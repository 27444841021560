.input-item{
    border-radius:8px;
    --border-color: transparent;
    border: 1px solid rgba(209, 227, 251, 0.7);

    &.item-has-focus::part(native){
        background: rgba(239, 244, 255, 0.85);
    }

  ion-icon{
    float:left;
    color:#C6DBF0;
  }

}

.item-has-focus.label-floating.sc-ion-label-ios-h,
.item-has-focus .label-floating.sc-ion-label-ios-h,
.item-has-placeholder.sc-ion-label-ios-h:not(.item-input).label-floating,
.item-has-placeholder:not(.item-input) .label-floating.sc-ion-label-ios-h,
.item-has-value.label-floating.sc-ion-label-ios-h,
.item-has-value .label-floating.sc-ion-label-ios-h{
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    font-size: 14px;
    margin-right: 0;
    margin-top: 10px;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h{
    text-align: right;
    font-weight:300;
    color: #7a888a;
    margin-top:0;
}

