/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Theme elements CSS */
@import "theme/input.scss";

/*
 * --------------
 * Fonts
 * --------------
 */

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");

@font-face {
  font-family: "Rubik";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url("../src/assets/fonts/PulpDisplay-ExtraBold.woff") format("woff");
  font-family: "Pulp Display ExtraBold";
  font-weight: 900;
}

@font-face {
  src: url("../src/assets/fonts/PulpDisplay-Bold.woff") format("woff");
  font-family: "Pulp Display Bold";
  font-weight: 700;
}

@font-face {
  src: url("../src/assets/fonts/PulpDisplay-Regular.woff") format("woff");
  font-family: "Pulp Display Regular";
  font-weight: 400;
}

/*
 * --------------
 * Colors
 * --------------
 */

$primary: #000;
$light: #f8f9fd;
$medium-blue: #e0e9fe;
$blue: #407bff;
$grey: #808080;
$grey-tint: #1a1a1a;
$dark-blue: #00092f;
$bg-white: #ffffff;

/********************/
$body-color: #f0f2f3;

/*
 * --------------
 * General
 * --------------
 */

html {
  --ion-default-font: "Pulp Display Bold";
}

body {
  //background: $body-color;
  background: #f0f2f3;
  color: $primary;
  font-family: "Pulp Display Regular";
}

.rtl {
  direction: rtl;
}

ion-content::part(background) {
  background: #f0f2f3;
  // border: 1px solid #E6E6E6;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.12);
  border-radius: 24px 24px 0px 0px;
}

.list-ios {
  background: #fafafa;
}

.error {
  font-size: 12px;
}

.no-border {
  --border-color: transparent;
}

.page-container {
  padding: 15px 0 0 0;
  text-align: center;
}

/*
 * --------------
 * Rewrite ion-color
 * --------------
 */

:root {
  ion-title {
    font-weight: 500;
    padding: 0;
    text-align: left;
    font-size: 24px;
    position: relative;
  }
}

//

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

//

/*
 * --------------
 * Buttons
 * --------------
 */

ion-button {
  height: 48px;
}

/* ==== BUTTONS ==== */
.button-primary.button-primary-blue {
  --background: $primary;
}

.button-primary,
.button-secondary {
  border-radius: 8px;
  display: flex;
  min-height: 48px;
}
.button-secondary.button-primary-blue {
  --background: $grey;
  --color: $primary;
}

ion-content {
  --padding-end: 40px;
  --padding-start: 40px;
  --padding-top: 40px;
  --padding-bottom: 40px;
}

/* ====== INGREDIENTS LIST ========= */
.ingredients-list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  transform: translateX(-12px);
}
.ingredients-list-overflow {
  max-height: 385px;
  overflow: scroll;
}

/* ====== FOOTER CONTROL BUTTONS ===== */

.controls {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.controls__prev {
  font-family: "Pulp Display ExtraBold";
  font-size: 12px;
  line-height: 46px;
  text-transform: uppercase;
  background: transparent;
  color: #2d2d2d;
}

.controls__prev img {
  margin-right: 20px;
}

.controls__next {
  direction: rtl;
  font-family: "Pulp Display ExtraBold";
  font-size: 12px;
  text-transform: uppercase;
  padding: 12px 16px 12px 24px;
  background: $primary;
  color: #fff;
  border-radius: 16px;
}
.controls__next[disabled] {
  background: #d8d8d8;
  color: #b8b8b8;
}

.controls__next:hover {
  background: $grey-tint;
}

.controls__next img {
  margin-left: 20px;
}
.app-ingredient-item {
  flex: 0 0 calc((100vw - 30px - 27px) / 3);
  margin: 6px;
}
